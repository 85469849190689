import React from "react"


export function Logo({ fillColor, title, desc, titleId, ariaHidden, className }) {

	return (
		<svg
			aria-labelledby={titleId}
			aria-hidden={ariaHidden}
			className={className}
			focusable="false"
			role="img"
			width="512px"
			height="511px"
			viewBox="0 0 512 511"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
		>

			<title id={titleId}>{title || `YG Logo`}</title>
			<desc>{desc || `Grey circle with the letters "Y" and "G" in the middle.`}</desc>

			<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<path d="M401.884803,46.1692015 C468.432494,92.399683 512,169.393223 512,256.5625 C512,390.164406 409.656296,499.86266 279.090953,511.535196 C288.140321,505.32176 296.568289,497.825905 304.373047,489.041016 C316.124349,475.681641 324.473958,461.332682 329.421875,445.994141 C334.394046,429.74363 336.928879,411.115228 337.026372,390.108934 L337.026372,390.108934 L337.029297,388.845703 L337.029297,379.382812 L218.650391,379.382812 L218.650391,420.017578 L290.085938,420.017578 L290.014724,420.711718 C287.888244,440.578071 278.449092,457.664399 261.697266,471.970703 C244.750651,486.319661 225.639323,493.494141 204.363281,493.494141 C179.376302,493.494141 156.182943,483.907552 134.783203,464.734375 C111.899089,444.076823 100.457031,417.791016 100.457031,385.876953 C100.457031,354.952474 110.723958,329.223307 131.257812,308.689453 C151.791667,287.784505 177.211589,277.332031 207.517578,277.332031 C237.993257,277.332031 264.288061,290.076145 286.40199,315.564371 L286.40199,315.564371 L286.777062,316 L332.578062,316 L332.578125,166.289062 Z M195.906576,7.65506 L287.070313,166.289062 L287.070627,260.682229 C281.241672,256.628579 275.381541,253.148566 269.490234,250.242187 C250.069661,241.088542 229.164714,236.511719 206.775391,236.511719 C165.089193,236.511719 129.711589,251.046224 100.642578,280.115234 C71.5735677,309.060547 57.0390625,344.314453 57.0390625,385.876953 C57.0390625,398.783751 58.4760891,411.105784 61.3501423,422.843054 C23.0991562,378.110808 0,320.034203 0,256.5625 C0,135.874235 83.5152729,34.692008 195.906576,7.65506 Z M256,0.5625 C294.032576,0.5625 330.128059,8.85618053 362.576525,23.7336178 L309.921875,115.507812 L244.309637,0.824676152 C248.184622,0.650515169 252.082032,0.5625 256,0.5625 Z" id="Combined-Shape" fill={fillColor || `#333333`}></path>
			</g>
		</svg>
	)
}


// TODO: Add query to get the theme color from 'gatsby-plugin-manifest' option and make it the default fillColor

Logo.defaultProps = {
	titleId: `headerLogo`,
	ariaHidden: `false`,
}
